import {Component, OnInit, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith, map} from 'rxjs/operators';
import {Condition} from '../models';
import {IOService} from '../io.service';
import {ConditionDetailComponent} from '../condition-detail/condition-detail.component';
import {MatDialog} from '@angular/material';
import {FormControl} from '@angular/forms';

import {
    Location,
    LocationStrategy,
    PathLocationStrategy
} from '@angular/common';

declare const ga: Function;

@Component({
    selector: 'app-condition-search',
    templateUrl: './condition-search.component.html',
    styleUrls: ['./condition-search.component.css'],
    providers: [Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }],
})
export class ConditionSearchComponent implements OnInit {
    filteredConditions: Observable < any[] > ;
    conditionCtrl: FormControl;
    @Input() conditions: Condition[];
    public queryArray: string[];
    location: Location;

    constructor(private io: IOService, public dialog: MatDialog, location: Location) {
        this.location = location;
        this.conditionCtrl = new FormControl();

        this.filteredConditions = this.conditionCtrl.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                startWith(''),
                map(condition => this.filterConditions(condition))
            );

    }

    filterConditions(query: string) {
        query = query ? query.trim() : '';
          console.log(query.length);
        const queryArray = query.split(' ');
        if (query.length < 2){
          return [];
        }
        this.queryArray = queryArray;
        let result = this.conditions;
        for (let k = 0; k < queryArray.length; k++) {
            const singleQeury = queryArray[k];
            result = result.filter(condition =>
                condition.name.toUpperCase().indexOf(singleQeury.toUpperCase()) >= 0 ||
                condition.category.toUpperCase().indexOf(singleQeury.toUpperCase()) >= 0 ||
                condition.detail.toUpperCase().indexOf(singleQeury.toUpperCase()) >= 0 ||
                condition.tags.filter(
                    tag => tag.name.toUpperCase().indexOf(singleQeury.toUpperCase()) >= 0).length > 0
            );
        }

        return result;
    }


    replaceDetail(detail: string, query: string) {
        if (detail && query && detail != undefined) {
           const queryArray = query.split(' ');
            detail = detail.replace(/<(?:.|\n)*?>/gm, '');
            const index = detail.toUpperCase().indexOf(queryArray[0].toUpperCase());
            if (index >= 0) {
                return detail.substring(index);
            } else {
                return detail;
            }
        }
    }


    ngOnInit(): void {



    }




    showConditonDetail(id) {
        this.location.replaceState('/condition/' + id);
        ga('set', 'page', '/condition/' + id);
        ga('send', 'pageview');
        const dialogRef = this.dialog.open(ConditionDetailComponent, {
            data: {
                condition: id,
                object: this.conditions.find(function(element) {return element.id == id; })
            },
            height: '90vh',
            width: '100%'
        });


    }

}
