import {
    Component,
    OnInit,
    ViewChild,

    ElementRef,
    ChangeDetectorRef,

} from '@angular/core';


import {
    Condition,
    Category,
    Page
} from '../models';

import {IOService} from '../io.service';

import {
    ConditionDetailComponent
} from '../condition-detail/condition-detail.component';
import {
    MatDialog
} from '@angular/material';

import {
    NgxCarousel,
    NgxCarouselStore
} from 'ngx-carousel';
import {

    ActivatedRoute,

} from '@angular/router';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy
} from '@angular/common';



declare const ga: Function;
declare var jQuery: any;

@Component({
    selector: 'app-condition-list',
    templateUrl: './condition-list.component.html',
    styleUrls: ['./condition-list.component.css'],
    providers: [Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }],


})




export class ConditionListComponent implements OnInit {

    conditions: Condition[] = [];
    categories: Category[] = [];
    pages: Page[] = [];



    public carouselOne: NgxCarousel;
    public carouselDetail: NgxCarouselStore[] = [];

    public selectedIndex = 0;


    public detailId: number;
    public pageId: number;


    selectedIndexChange(data) {
        jQuery('.button-collapse').sideNav('hide');
        this.selectedIndex = data;

    }

    onmoveFn(data: NgxCarouselStore, index) {

        this.carouselDetail[index] = data;
    }

    /* This will be triggered after carousel viewed */
    afterCarouselViewedFn(data, index) {

        this.carouselDetail[index] = data;

    }


    moveLeft() {

        if (this.carouselDetail[this.selectedIndex].isFirst && this.selectedIndex > 0) {
            this.selectedIndex = this.selectedIndex - 1;
        } else if (this.carouselDetail[this.selectedIndex].isFirst && this.selectedIndex == 0) {
            this.selectedIndex = this.totalCounts(this.categories) - 1;
        }

    }


    moveRight() {
        if (this.carouselDetail[this.selectedIndex].isLast && this.selectedIndex < (this.totalCounts(this.categories) - 1)) {
            this.selectedIndex = this.selectedIndex + 1;
        } else if (this.carouselDetail[this.selectedIndex].isLast && this.selectedIndex == (this.totalCounts(this.categories) - 1)) {
            this.selectedIndex = 0;
        }

    }

    showPageDetail(id) {
        this.location.replaceState('/page/' + id);
        ga('set', 'page', '/page/' + id);
        ga('send', 'pageview');
        const dialogRef = this.dialog.open(ConditionDetailComponent, {
            data: {
                page: id,
                object: this.pages.find(function(element) {return element.id == id; })
            },
            height: '90vh',
            width: '100%'
        });
    }

    showConditonDetail(id) {
        this.location.replaceState('/condition/' + id);
        ga('set', 'page', '/condition/' + id);
        ga('send', 'pageview');
        const dialogRef = this.dialog.open(ConditionDetailComponent, {
            data: {
                condition: id,
                object: this.conditions.find(function(element) {return element.id == id; })
            },
            height: '90vh',
            width: '100%'
        });
    }

    location: Location;
    constructor(private io: IOService,
        private cdr: ChangeDetectorRef,
        private element: ElementRef,

        public dialog: MatDialog,
        private route: ActivatedRoute,
        location: Location) {

        this.location = location;

        this.route.params.subscribe(res => this.detailId = res.condition);
        this.route.params.subscribe(res => this.pageId = res.page);
        if (this.detailId) {
            this.showConditonDetail(this.detailId);
        }
        if (this.pageId) {
            this.showPageDetail(this.pageId);
        }
    }


    ngOnInit() {

         this.io.list(Page, this.io.APIURL + '/pages/').subscribe(pages => this.pages = pages);
         this.io.list(Category, this.io.APIURL + '/categories/').subscribe(categories => this.categories = categories);

         this.io.list(Condition, this.io.APIURL + '/conditions/').subscribe(conditions => this.conditions = conditions);






        // User screen size
        const screenHeight = window.screen.height;
        const screenWidth = window.screen.width;

        // Actual space available in navigator
        const actualHeight = window.innerHeight;
        const actualWidth = window.innerWidth;

        this.carouselOne = {
            grid: {
                xs: 2,
                sm: 2,
                md: 4,
                lg: 4,
                all: 0
            },
            slide: 2,
            speed: 400,
            interval: 100000,
            point: {
                visible: false
            },
            load: 100,
            touch: false,
            loop: false,
            custom: 'banner'
        };


    }

    totalCounts(data) {
        let total = 0;

        data.forEach((d) => {
            total++;
        });

        return total;
    }




}
