import {
    Component,
    OnInit,

    Input,
    Inject
} from '@angular/core';


import {
    Condition,
    Category,
    Page
} from '../models';

import {IOService} from '../io.service';


import {
    MAT_DIALOG_DATA
} from '@angular/material';
import {
    NgxCarousel
} from 'ngx-carousel';
import {
    DomSanitizer,
    SafeUrl
} from '@angular/platform-browser';
@Component({
    selector: 'app-condition-detail',
    templateUrl: './condition-detail.component.html',
    styleUrls: ['./condition-detail.component.css']
})
export class ConditionDetailComponent implements OnInit {
    public carouselOne: NgxCarousel;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private io: IOService, private _sanitizationService: DomSanitizer) {
        this.url = this._sanitizationService.bypassSecurityTrustResourceUrl('');
    }

    @Input() condition: Condition;
    @Input() page: Page;
    public url;
    public iframeHeight = 'no-height';
    public opacity = '';
    public pageGrey = '';
    ngOnInit() {


        if (this.data.condition) {
            if (this.data.object){
              this.condition = this.data.object;
            } else{

              this.io.get(Condition, this.io.APIURL + '/conditions/' + this.data.condition).subscribe(condition => this.condition = condition);
            }

            this.carouselOne = {
                grid: {
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    all: 0
                },
                slide: 1,
                speed: 400,
                interval: 100000,
                point: {
                    visible: true,
                    pointStyles: `
            .ngxcarouselPoint {
              list-style-type: none;
              text-align: right;
              margin: 0;
              white-space: nowrap;
              overflow: auto;
              box-sizing: border-box;
              padding-right: 20px;
              clear: both;
            }
            .ngxcarouselPoint li {
              display: inline-block;
              border-radius: 50%;
              border: 2px solid #648226;
              padding: 4px;
              margin: 0 3px;
              transition-timing-function: cubic-bezier(.17, .67, .83, .67);
              transition: .4s;
              transform: scale(1.2);
              cursor: pointer;
            }
            .ngxcarouselPoint li.active {
                background: #648226;

            }
          `
                },
                load: 2,
                touch: true,
                loop: true,
                custom: 'banner'
            };
        } else if (this.data.page) {
            if (this.data.object){
              this.page = this.data.object;
            } else{
              this.io.get(Condition, this.io.APIURL + '/pages/' + this.data.page).subscribe(page => this.page = page);
            }

        }

    }
    videoUrl(videoUrl): void {
        this.iframeHeight = 'auto-height';
        this.pageGrey = 'overlay-grey';
        this.opacity = 'half-opacity';
        this.url = this._sanitizationService.bypassSecurityTrustResourceUrl(
          'https://player.vimeo.com/video/' + videoUrl + '?autoplay=1color=3f3f3f&byline=0&portrait=0');

    }
    closeVideo(): void {
        this.iframeHeight = 'no-height';
        this.opacity = '';
        this.pageGrey = '';
        this.url = this._sanitizationService.bypassSecurityTrustResourceUrl('');
    }
    getCondition(id): void {



    }

    getPage(id): void {


    }


}
