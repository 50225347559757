import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule, Routes} from '@angular/router';
import {ConditionListComponent} from './condition-list/condition-list.component';


const routes: Routes = [

  {
    path: 'condition/:condition', component: ConditionListComponent
  },
   {
    path: 'page/:page', component: ConditionListComponent
  },
  {path: '', component: ConditionListComponent},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule]

})
export class AppRoutingModule {
}
