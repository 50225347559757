export class Condition {
    id: number;
    type: number;
    name: string;
    images: string[];
    category: string;
    videos: object[];
    wear: boolean;
    ceaseWear: boolean;
    changeLens: boolean;
    counseling: boolean;
    dd: boolean;
    dw: boolean;
    gp: boolean;
    changeSolutions: boolean;
    changeCare: boolean;
    replace: boolean;
    review: boolean;
    rewettingDrops: boolean;
    rx: boolean;
    SiHy: boolean;
    toric: boolean;
    detail: string;
    tags: Tag[];
}

export class Tag {
    id: number;
    name: string;
}

export class Category {
    id: number;
    type: number;
    name: string;
    label: string;
    conditions: object[];
}

export class Page {
    id: number;
    menu: number;
    name: string;
    content: string;
}