import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-omni-navbar',
  templateUrl: './omni-navbar.component.html',
  styleUrls: ['./omni-navbar.component.css']
})
export class OmniNavbarComponent implements OnInit {
  menuItems = [
    {
      name: 'CORE',
      url: 'https://core.uwaterloo.ca/',
      current_site: false,
      background: '#ffffff',
      image: '/assets/images/core-logo.webp'
    },
    {
      name: 'Contact Lens Update',
      url: 'https://contactlensupdate.com/',
      current_site: false,
      background: '#658439',
      image: '/assets/images/clu.webp'
    },
    {
      name: 'Contact Lens Compendium',
      url: 'https://compendium.contactlensupdate.com/',
      current_site: false,
      background: '#00a5b7',
      image: '/assets/images/compendium.webp'
    },
    {
      name: 'Clinical Contact Lens Management Guide',
      url: 'https://clmanagement.contactlensupdate.com/',
      current_site: true,
      background: '#777777',
      image: '/assets/images/cclm.webp'
    },
    // {
    //   name: 'Contact Lens Recycling',
    //   url: 'https://contactlensrecycling.com/',
    //   current_site: false,
    //   background: '#1b1b4d',
    //   image: null
    // }
  ];

  constructor() { }

  ngOnInit() {
  }

}
