
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Headers, Http, Response } from '@angular/http';

@Injectable()
export class IOService {
  private headers = new Headers({'Content-Type': 'application/json'});
    public APIURL = 'https://cclm.media-doc.io';

    constructor(
      private http: Http,
    ) {}

  /* Generic CRUD Services */
  private handleError (error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  public get(model: any, route: string): Observable<any> {
    // You'll need to add the APIURL manually unless you
    // base your request on something like the practice.url
    const object = new model();
    return this.http.get(route, {headers: this.headers}).pipe(
      map(response => {
        const json = response.json();
        Object.keys(json).forEach(function (key) {
          object[key] = json[key];
        });
        return object;
      }),catchError(this.handleError),);
  }

  public list(model: any, route: string, ): Observable<any> {
    // You'll need to add the APIURL manually unless you
    // base your request on something like the practice.url
    const object = new model();
    return this.http.get(route, {headers: this.headers}).pipe(
      map(response => {
        const json = response.json(),
          output = [];
        for (const item of json) {
          const obj = new model();
          Object.keys(item).forEach(key => {
            obj[key] = item[key];
          });
          output.push(obj);
        }
        return output;
      }),catchError(this.handleError),);
  }

  public create(object: any, route: string) {
    const url = route;
    if ('url' in object && object['url'] !== typeof 'undefined') {
      throw new Error('Object already created. Try update instead.');
    }
    return this.http.post(url, JSON.stringify(object), {headers: this.headers}).pipe(
      map(response => {
        const json = response.json();
        Object.keys(json).forEach(function (key) {
          object[key] = json[key];
        });
        return object;
      }),catchError(this.handleError),);
  }

  public update(object: any): Observable<any> {
    if (!('url' in object) || object['url'] === typeof 'undefined') {
      throw new Error('Object is not yet created. Use create instead of update.');
    }
    return this.http.patch(object['url'], JSON.stringify(object), {headers: this.headers}).pipe(
      map(response => {
        const json = response.json();
        Object.keys(json).forEach(function (key) {
          object[key] = json[key];
        });
        return object;
      }),
      catchError(this.handleError),);
  }

  public remove(object: any): Observable<any> {
    if (!('url' in object) || object['url'] === typeof 'undefined') {
      throw new Error('Object is not yet created. Nothing to delete.');
    }
    return this.http.delete(object['url'], {headers: this.headers}).pipe(
      map(response => {
        return object;
      }),
      catchError(this.handleError),);
  }


}
