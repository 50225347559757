import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], term: any): any {
        if (term[2] == 'EXCLUDE') {
            return term ?
                items.filter(item => item[term[0]].indexOf(term[1]) == -1) :
                items;
        } else {
            return term ?
                items.filter(item => item[term[0]].indexOf(term[1]) !== -1) :
                items;
        }

    }


}

@Pipe({
    name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
    transform(items: any[], sortedBy: any): any {

        if (sortedBy[1] == 'DESC') {
            return items.sort(function(a, b) {
                return(
                        a[sortedBy[0]] > b[sortedBy[0]]) ? -1 :
                    ((b[sortedBy[0]] > a[sortedBy[0]]) ? 1 :
                        0
                    );
            });
        } else {
            return items.sort(function(a, b) {
                return(
                        a[sortedBy[0]] > b[sortedBy[0]]) ? 1 :
                    ((b[sortedBy[0]] > a[sortedBy[0]]) ? -1 :
                        0
                    );
            });
        }



    }
}


@Pipe({
    name: 'highlight'
})

export class HighlightPipe implements PipeTransform {

    transform(value: any, args: any): any {
       //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        args.forEach((d) => {

            if (value != undefined && '</mark>'.indexOf(d) == -1){
                d = d.replace(/[|&;$%@"<>()+,]/g, '');
                const re = new RegExp(d, 'gi');
                value = value.replace(re, '<mark>' + d + '</mark>');
            }

        });


        return value;
    }
}
